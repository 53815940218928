import { render, staticRenderFns } from "./form.vue?vue&type=template&id=d7c3a3b0&"
import script from "./form.vue?vue&type=script&lang=js&"
export * from "./form.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBreadcrumbLink: require('@chakra-ui/vue').CBreadcrumbLink, CBreadcrumbItem: require('@chakra-ui/vue').CBreadcrumbItem, CBreadcrumb: require('@chakra-ui/vue').CBreadcrumb, CDivider: require('@chakra-ui/vue').CDivider, CHeading: require('@chakra-ui/vue').CHeading, CFormLabel: require('@chakra-ui/vue').CFormLabel, CImage: require('@chakra-ui/vue').CImage, CInput: require('@chakra-ui/vue').CInput, CFormControl: require('@chakra-ui/vue').CFormControl, CFlex: require('@chakra-ui/vue').CFlex, CInputGroup: require('@chakra-ui/vue').CInputGroup, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CInputLeftAddon: require('@chakra-ui/vue').CInputLeftAddon, CAlertDialogOverlay: require('@chakra-ui/vue').CAlertDialogOverlay, CAlertDialogBody: require('@chakra-ui/vue').CAlertDialogBody, CAlertDialogFooter: require('@chakra-ui/vue').CAlertDialogFooter, CAlertDialogContent: require('@chakra-ui/vue').CAlertDialogContent, CAlertDialog: require('@chakra-ui/vue').CAlertDialog})
